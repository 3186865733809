import styled from "styled-components";
import imgMiniaturas from "../images/background-miniaturas.png";

export const Container = styled.div`
  max-width: 1440px;
  width: 100%;

  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ///// Margens laterais
  .beyond,
  .extra,
  .contact,
  #whitelist
  h1,
  h2,
  h4 {
    box-sizing: border-box;
    /* padding: 0 5%!important; */
  }

  .cards,
  #contact {
    box-sizing: border-box;
    margin-bottom: 20px;
    width: 100%;
  }

  #roadmap,
  #team {
    padding: 0!important;
  }

  ////

  .first-block {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    padding: 20px 60px 0px 60px;

    @media screen and (max-width: 500px) {
      padding: 20px 20px 0px 20px;
    }

    > h1 {
      color: #000000;

      font-family: "Gloria Hallelujah", cursive;
      font-weight: 400;
      font-size: 40px;
      line-height: 48px;

      padding: 20px 0px 5px 0px;

      @media screen and (max-width: 500px) {
        width: 100%;
        font-size: 26px;
        margin-bottom: 20px;
        padding: 0px;
        text-align: end;
      }
    }

    > p {
      font-family: "Raleway", sans-serif;
      font-weight: 400;
      font-size: 24px;
      line-height: 40px;
      padding: 10px 0 50px 0;

      @media screen and (max-width: 1440px) {
        font-size: 20px;
      }

      @media screen and (max-width: 500px) {
        width: 100%;
        font-size: 18px;
        line-height: 20px;
        margin-top: 15px;
        padding: 0px;
        text-align: end;
      }
    }

    > span {
      font-family: "Raleway", sans-serif;
      font-weight: 400;
      font-size: 24px;
      line-height: 40px;
      padding: 20px 0 40px 0;
      align-self: flex-end;

      @media screen and (max-width: 1440px) {
        font-size: 20px;
      }

      @media screen and (max-width: 500px) {
        font-size: 16px;
        line-height: 20px;
        text-align: end;
      }
    }
  }

  .second-block {
    flex: 1;
    width: 100%;
    width: 75vw;
    margin: auto;

    & #theart {
      width: 100%;
    }

    #roadmap {
      margin-bottom: 30px;
    }

    .wearables {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      overflow-y: hidden;
      overflow-x: scroll;
      scroll-behavior: smooth;
      
      & ::-webkit-scrollbar {
        width: 10px;
      }
      & ::-webkit-scrollbar-thumb {
        background: #fff;
      }
      & ::-webkit-scrollbar-track {
        background: #fff;
      }

      img {
        width: 450px;
        margin-right: 10px;
        
      }
    }

    .btn-scroll {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;

      img {
        cursor: pointer;
        padding: 0 10px;
      }
    }

    @media screen and (max-width: 500px) {
      max-width: 500px;
      width: 100%;
      margin-top: 30px;
    }
  }

  .writing-text {
    margin: 0 0 20px 0;

    h1 {
      font-family: "Gloria Hallelujah", cursive;
      font-weight: 400;
      font-size: 32px;
      line-height: 64px;
      margin-bottom: 15px;

      &:nth-child(3) {
        margin-top: 10px;
        margin-bottom: 20px;
      }

      @media screen and (max-width: 500px) {
        font-size: 22px;
      }
    }

    p {
      font-family: "Raleway", sans-serif;
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;

      &:nth-child(n + 5) {
        margin: 40px 0;

        @media screen and (max-width: 500px) {
          margin: 40px 0 10px 0;
        }
      }

      @media screen and (max-width: 500px) {
        font-size: 15px;
      }
    }

    ul {
      margin: 50px 50px 50px 60px;

      li {
        font-family: "Raleway", sans-serif;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        margin: 5px;
      }
    }

    @media screen and (max-width: 1440px) {
      margin: 0 50px;
    }

    @media screen and (max-width: 500px) {
      margin: 0 20px;
    }
  }

  .writing {
    width: 100%;
    height: 1030px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;

    > h1 {
      width: 100%;
      font-family: "Gloria Hallelujah", cursive;
      font-weight: 400;
      font-size: 32px;
      line-height: 64px;
      text-align: start;

      @media screen and (max-width: 500px) {
        width: 91%;
        font-size: 24px;
      }
    }

    @media screen and (max-width: 1366px) {
      width: 95%;
    }

    @media screen and (max-width: 500px) {
      height: 100%;
    }
  }

  .writing-1 {
    flex: 1;
    /* height: 300px; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    background-color: #f5f3f3;
    margin: 20px;
    padding: 20px 30px;
    border-radius: 20px;

    > h4 {
      font-family: "Gloria Hallelujah", cursive;
      font-weight: 400;
      font-size: 18px;
      line-height: 40px;
      align-self: center;
    }

    > p {
      font-family: "Raleway", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }

    > button {
      font-family: "Gloria Hallelujah", cursive;
      font-weight: 400;
      font-size: 14px;
      border-radius: 6px;
      border: none;
      background-color: #6052ff;
      color: #ffffff;
      padding: 2px 5px;
      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
      align-self: center;
    }
  }

  .writing-2 {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    background-color: #f5f3f3;

    padding: 20px 30px 60px 30px;
    border-radius: 20px;

    > h4 {
      font-family: "Gloria Hallelujah", cursive;
      font-weight: 400;
      font-size: 18px;
      line-height: 40px;
      align-self: center;
    }

    > p {
      font-family: "Raleway", sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .bots {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: space-between;

    > p {
      font-family: "Raleway", sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 25px;

      @media screen and (max-width: 500px) {
        line-height: 25px;
      }
    }

    span {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 500px) {
        flex-direction: column;
      }
    }
  }

  .bots-1 {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 10px;

    @media screen and (max-width: 500px) {
      height: 300px;
      margin-bottom: 50px;
    }

    > p {
      font-family: "Raleway", sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 45px;

      @media screen and (max-width: 500px) {
        line-height: 25px;
        margin: 10px 0;
      }
    }
  }

  .bots-2 {
    width: 100%;
    height: 95%;
    margin-left: 20px;
    align-self: flex-end;

    @media screen and (max-width: 500px) {
      width: 325px;
    }

    div {
      width: 100%;
      height: 80px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      &:nth-child(1) {
        @media screen and (max-width: 500px) {
          height: 250px;
        }
      }

      &:nth-child(2) {
        @media screen and (max-width: 500px) {
          height: 125px;
        }
      }
      &:nth-child(3) {
        @media screen and (max-width: 500px) {
          height: 200px;
        }
      }
      &:nth-child(4) {
        @media screen and (max-width: 500px) {
          height: 110px;
        }
      }
      &:nth-child(5) {
        @media screen and (max-width: 500px) {
          height: 100px;
        }
      }
      &:nth-child(6) {
        @media screen and (max-width: 500px) {
          height: 80px;
        }
      }
      &:nth-child(7) {
        @media screen and (max-width: 500px) {
          height: 130px;
        }
      }
      &:nth-child(8) {
        @media screen and (max-width: 500px) {
          height: 150px;
        }
      }
      &:nth-child(9) {
        @media screen and (max-width: 500px) {
          height: 100px;
        }
      }

      .description {
        font-family: "Raleway", sans-serif;
        font-weight: 400;
        font-size: 15px;
        line-height: 15px;
        padding: 15px;

        @media screen and (max-width: 500px) {
          line-height: 17px !important;
          margin: 0px 0px !important;
          font-size: 14px !important;
        }
      }

      > p {
        font-family: "Gloria Hallelujah", cursive;
        font-weight: 400;
        font-size: 12px;
        line-height: 35px;

        display: flex;
        align-items: center;
        justify-content: flex-start;

        @media screen and (max-width: 500px) {
          line-height: 30px;
          font-size: 10px;
        }

        &:nth-child(n + 1) {
          margin-top: -12px;
        }

        > span {
          width: 15px;
          height: 15px;
          border-radius: 25px;
          background-color: #336699;
          margin-left: -9px;
          margin-right: 15px;

          z-index: 5;
        }

        > button {
          margin-left: 50px;
          font-family: "Gloria Hallelujah", cursive;
          font-weight: 400;
          font-size: 14px;
          border-radius: 6px;
          border: none;
          background-color: #6052ff;
          color: #ffffff;
          padding: 0 8px;
          box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
          justify-items: end !important;

          @media screen and (max-width: 500px) {
            margin-top: 8px !important;
            margin-left: 44px;
          }
        }
      }
    }
  }

  .nivel {
    > span {
      margin-left: -10px !important;
      text-align: start;
    }
  }

  /* .bar-progress-expanded {
    @media screen and (max-width: 500px) {
      height: 520px !important;
      width: 100%;
      border-left: 5px solid #66ffcc;
      transition: all 450ms;
    }
  } */

  .bar-progress {
    width: 100%;
    border-left: 5px solid #66ffcc;
    transition: all 450ms;

    /* @media screen and (max-width: 500px) {
      height: 520px !important;
    } */

    &:nth-child(1) {
      height: 120px;
      @media screen and (max-width: 500px) {
        height: 250px;
      }
    }

    &:nth-child(2) {
      padding-bottom: 43px;
      margin-top: -10px;
      transition: all 450ms;

      /* @media screen and (max-width: 500px) {
        height: 130px !important;
      } */
    }

    &:nth-child(3) {
      @media screen and (max-width: 500px) {
        height: 200px;
      }
    }

    &:nth-child(4) {
      padding-bottom: 60px;
      margin-top: -5px;
      transition: all 450ms;

      @media screen and (max-width: 500px) {
        height: 150px !important;
      }
    }
    &:nth-child(5) {
      @media screen and (max-width: 500px) {
        height: 110px;
      }
    }
    &:nth-child(6) {
      padding-bottom: 35px;
      margin-top: -11px;
      transition: all 450ms;

      @media screen and (max-width: 500px) {
        height: 70px !important;
      }
    }
    &:nth-child(7) {
      padding-bottom: 35px;
      margin-top: -11px;
      transition: all 450ms;

      @media screen and (max-width: 500px) {
        height: 100px !important;
      }
    }
    &:nth-child(8) {
      padding-bottom: 35px;
      margin-top: -11px;
      transition: all 450ms;

      @media screen and (max-width: 500px) {
        height: 140px;
      }
    }
    &:nth-child(9) {
      @media screen and (max-width: 500px) {
        height: 100px !important;
      }
    }
    &:nth-child(10) {
      padding-bottom: 35px;
      margin-top: -11px;
      transition: all 450ms;

      @media screen and (max-width: 500px) {
        height: 150px !important;
      }
    }
    &:nth-child(12) {
      padding-bottom: 35px;
      margin-top: -11px;
      transition: all 450ms;
      @media screen and (max-width: 500px) {
        height: 100px !important;
      }
    }
    &:nth-child(14) {
      padding-bottom: 35px;
      margin-top: -11px;
      transition: all 450ms;
    }
    &:nth-child(16) {
      padding-bottom: 35px;
      margin-top: -11px;
      transition: all 450ms;
    }
  }

  .third-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    @media screen and (max-width: 500px) {
      margin: 0;
    }

    .block-margin {
      font-family: "Gloria Hallelujah", cursive;
      padding: 0 3%!important;
    }

    .beyond-title {
      font-size: 32px;
      margin-bottom: 10px;
    }

    .bh-family-title {
      font-size: 32px;
      margin-bottom: 30px;
    }

    .background-miniaturas {
      width: 102%;
      height: 75px;

      background-image: url(${imgMiniaturas});
    }

    .beyond {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      
      > p {
        width: 100%;
        text-align: justify;
        /* padding: 20px 0; */
        font-family: "Raleway", sans-serif;
        font-size: 24px;
        font-weight: 400;

        @media screen and (max-width: 500px) {
          font-size: 18px;
          line-height: 23px;
        }
      }

      > p:nth-child(n + 3) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      > button {
        width: 60px;
        font-family: "Gloria Hallelujah", cursive;
        font-weight: 400;
        font-size: 16px;
        border-radius: 11px;
        border: none;
        background-color: #6052ff !important;
        color: #ffffff;
        padding: 2px 5px;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
        cursor: pointer;
      }

      .box-gray {
        width: 100%;
        height: 342px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        background-color: #f5f3f3;
        border-radius: 10px;
        margin: 20px 0;
        padding: 20px 30px;

        @media screen and (max-width: 500px) {
          height: 100%;
        }

        p {
          font-family: "Raleway", sans-serif;
          font-size: 15px;
          font-weight: 400;

          @media screen and (max-width: 500px) {
            text-align: justify;
            line-height: 21px;
            margin: 55px 0;
          }
        }

        ul {
          width: 100%;
          padding: 10px 15px;

          @media screen and (max-width: 500px) {
            padding: 25px 15px;
          }

          li {
            font-family: "Raleway", sans-serif;
            font-size: 15px;
            font-weight: 400;
            padding: 3px 0;

            @media screen and (max-width: 500px) {
              padding: 10px 0px;
            }
          }
        }
      }
    }

    > h1 {
      width: 100%;
      font-size: 32px;
      font-family: "Gloria Hallelujah", cursive;
      padding: 20px 0;

      @media screen and (max-width: 500px) {
        font-size: 15px;
      }

      > span {
        font-family: "Gloria Hallelujah", cursive;
        font-weight: 400;
        font-size: 18px;
        margin-left: 50px;

        @media screen and (max-width: 500px) {
          font-size: 10px;
          margin-left: 3px;
        }
      }
    }
  }

  .cards {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px 0;

    @media screen and (max-width: 500px) {
      flex: none;
      justify-content: center;
      overflow: hidden;

      & .link {
        /* margin-top: 20px!important; */
        padding: 0 10px;
      }

      & .link p{
        font-size: 30px!important;
        line-height: 1.5!important;
      }
    }

    > img {
      width: 260px;
      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);

      &:nth-child(N + 6) {
        margin-top: 25px;
      }

      @media screen and (max-width: 1366px) {
        width: 230px;
      }

      @media screen and (max-width: 500px) {
        /* width: calc(48vw - 12px); */
        width: 100%;
        margin: 0 5px 10px 5px;
        &:nth-child(N + 6) {
          display: none;
        }
      }
    }

    .link {
      width: 260px;
      height: 364px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 25px;
      background-color: #ffb800;
      border: none;
      outline: none;
      text-decoration: none;
      cursor: pointer;

      font-family: "Gloria Hallelujah", cursive;
      font-weight: 400;
      font-size: 24px;
      line-height: 48px;

      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);

      p {
        width: 200px;
        text-align: center;
      }

      @media screen and (max-width: 1366px) {
        width: 230px;
        height: 322px;
      }

      @media screen and (max-width: 500px) {
        /* width: calc(48vw - 12px); */
        /* height: calc((48vw - 12px) * 1.4); */
        width: 100%;
        height: 600px;
        margin: 0 0 10px 0;
        font-size: 14px;
      }
    }
  }

  .text {
    max-width: 1440px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px 0;

    @media screen and (max-width: 1280px) {
      margin-top: 100px;
    }

    @media screen and (max-width: 500px) {
      width: 95%;
      margin: 0 auto;
    }

    > h4 {
      width: 100%;
      font-family: "Gloria Hallelujah", cursive;
      font-weight: bold;
      font-size: 32px;
      line-height: 64px;
      margin: 40px 0;
      padding-left: 4px;
      box-sizing: border-box;

      @media screen and (max-width: 1366px) {
        width: 95%;
      }
    }
  }

  .text-1 {
    flex: 1;
    max-width: 1440px;
    width: 100%;
    height: 300px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fcfcfc;
    /* margin: 20px; */
    border-radius: 20px;

    // The Art - imagem
    > img {
      width: 327px;

      @media screen and (max-width: 768px) {
        width: 90vw;
        margin-top: 10px;
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0px 20px;

      @media screen and (max-width: 500px) {
        height: 100%;
        padding: 10px;
        text-align: justify;
      }

      > p {
        font-family: "Raleway", sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        text-align: justify;
        margin-top: 0;

        @media screen and (max-width: 500px) {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
    // The Art - div com texto e imagem
    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
      width: 90vw;
      height: 400px;
    }
  }

  .extra {
    flex: 1;
    max-width: 1440px;
    width: 100%;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h2 {
      font-family: "Gloria Hallelujah", cursive;
      font-size: 32px;
      margin: 45px 0;
      padding-left: 4px;
      box-sizing: border-box;
    }

    p {
      font-family: "Raleway", cursive;
      font-size: 24px;
      padding: 0;
      margin: 15px 0;

      @media screen and (max-width: 500px) {
        font-size: 18px;
      }
    }
  }

  .text-2 {
    flex: 1;
    max-width: 1440px;
    width: 100%;
    height: 300px;
    display: flex;
    align-items: flex-start;
    background-color: #fcfcfc;

    margin: 20px;
    padding: 50px 30px;
    border-radius: 20px;

    > div {
      flex: 1;

      > h4 {
        font-family: "Gloria Hallelujah", cursive;
        font-weight: 400;
        font-size: 18px;
        line-height: 50px;
        align-self: center;
      }

      > p {
        font-family: "Raleway", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
      }
    }

    .span {
      font-family: "Raleway", sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: justify;
      width: 300px;
    }

    @media screen and (max-width: 500px) {
      flex: 1;
      flex-direction: column;
      height: 400px;
    }
  }

  @media screen and (max-width: 500px) {
    overflow: hidden;
  }

  .torquesa {
    background: rgba(0, 255, 255, 0.6);
  }

  .yellow {
    background: rgba(255, 255, 0, 0.6);
  }

  .green {
    background: rgba(204, 255, 102, 0.6);
  }

  .pink {
    background: rgba(255, 0, 102, 0.6);
  }

  .orange {
    background: rgba(255, 184, 0, 0.6);
  }

  .ul {
    margin: 3px !important;
  }

  .more {
    width: 60px;
    font-family: "Gloria Hallelujah", cursive;
    font-weight: 400;
    font-size: 16px;
    border-radius: 11px;
    border: none;
    background-color: #6052ff !important;
    color: #ffffff;
    padding: 2px 5px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    cursor: pointer;
  }

  .more-btn {
    width: 60px;
    font-family: "Gloria Hallelujah", cursive;
    font-weight: 400;
    font-size: 16px;
    border-radius: 11px;
    border: none;
    background-color: #6052ff !important;
    color: #ffffff;
    padding: 2px 5px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    cursor: pointer;
  }

  .more-text {
    padding: 30px 50px !important;
    line-height: 35px !important;

    @media screen and (max-width: 500px) {
      padding: 0px 5px !important;
      line-height: 23px !important;
    }
  }

  .more-text-btn {
    font-family: "Raleway", sans-serif !important;
    padding: 30px 50px !important;
    line-height: 25px !important;

    @media screen and (max-width: 500px) {
      margin-top: 90px;
      line-height: 18px !important;
      padding: 40px 20px !important;
    }
  }

  .acord {
    transition: all 850ms !important;
  }

  .roadmap-btn {
    width: 100%;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }

  .div-whiteList,
  .div-contact {
    max-width: 1440px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
      margin-top: 10px;
    }

    h4 {
      font-family: "Gloria Hallelujah", cursive;
      font-weight: bold;
      font-size: 32px;
      line-height: 64px;
      padding-left: 4px;
      box-sizing: border-box;
    }

    .whiteList {
      flex: 2;

      display: flex;
      flex-direction: column;
      align-items: flex-start;

      margin: 0 0 50px 0;

      p {
        font-family: "Raleway", sans-serif;
        font-weight: 400;
        font-size: 24px;
        margin-top: -1em;
      }

      .input {
        width: 400px;
        height: 46px;

        border-radius: 3px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        background-color: rgba(50, 168, 82, 0.3);

        @media screen and (max-width: 768px) {
          width: 90vw;
        }

        input {
          width: 90%;
          height: 100%;
          border: none;
          outline: none;
          padding: 10px;
          font-family: "Raleway", sans-serif;
          font-weight: 400;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.5);
          background-color: transparent;
        }

        input::placeholder {
          color: rgba(0, 0, 0, 0.5);
          font-family: "Raleway", sans-serif;
          font-weight: 400;
          font-size: 16px;
        }
      }
    }

    .social {
      flex: 1;

      display: flex;
      flex-direction: column;
      align-items: flex-end;

      margin: 0 0 50px 0;

      @media screen and (max-width: 768px) {
        align-items: flex-start;
      }

      .sociais {
        /* width: 200px; */

        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    /* border: 1px solid red; */
  }
  .div-contact {
    max-width: 1440px;
    width: 100%;
    margin-bottom: 2rem;

    .contact,
    .textArea {
      flex: 1;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      gap: 16px;
    }

    .contact {
      input,
      select {
        width: 370px;
        height: 20px;
        margin: 10px 0;
        border: none;
        border-radius: 3px;
        outline: none;
        padding: 15px;
        font-family: "Raleway", sans-serif;
        font-weight: 400;
        font-size: 16px;

        &:nth-child(1) {
          background-color: #4fbbf973;
        }
        &:nth-child(2) {
          background-color: #ff59ee73;
        }
        &:nth-child(3) {
          background-color: #ffe60073;
        }

        @media screen and (max-width: 500px) {
          width: 90%;
        }
      }

      select {
        box-sizing: content-box;
        color: #777777;

        option {
          background-color: #ffffb7;
          color: #000000;

          &:nth-child(1) {
            color: #777777;
          }
        }
      }
    }

    .textArea {
      height: 250px;
      background-color: #e8fbff99;

      display: flex;
      flex-direction: row;

      @media screen and (max-width: 768px) {
        width: 85vw;
        margin: auto;
        margin-top: 25px;
      }

      textarea {
        width: 100%;
        height: 100%;
        border: none;
        background-color: transparent;
        outline: none;
        padding: 15px;
        font-family: "Raleway", sans-serif;
        font-weight: 400;
        font-size: 16px;
        resize: none;

        @media screen and (max-width: 768px){
          height: 200px!important;
        }
      }
    }
  }

  // tamanho das fontes de titulo e secoes no mobile
  @media screen and (max-width: 500px) {
    h1,
    h2, 
    h4 {
      box-sizing: border-box!important;
      font-size: 25px!important;
      padding: 0;
      margin: 20px 0 0 0!important;
    }

    p {
      line-height: 24px!important;
    }

    .beyond-title {
      margin-top: 30px!important;
    }

    .beyond p{
      padding: 0!important;
    }

    .cards {
      margin-top: 20px;
    }

    #roadmap {
      margin-top: 80px!important;
      margin-bottom: 10px!important;
    }

    .whiteList p{
      margin-top: 10px!important;
      line-height: 30px!important;
    }

    .second-block {
      width: 95vw;
    }
  }
`;
