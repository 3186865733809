import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { styled } from "@mui/material/styles";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { BsArrowRight, BsInstagram } from "react-icons/bs";
import { FaXTwitter } from "react-icons/fa6";
import { Container } from "../../styles/HomeStyle";
import { card } from "../../listPersonality";
import scrollLeft from "../../images/scrollLeft.png";
import scrollRight from "../../images/scrollRight.png";
import img from "../../images/image-the-art.png";
import img2 from "../../images/detalhe-newton1 1.png";
import openSea from "../../images/openSea.png";
import { wearable } from '../../data/wearable';
import spatial from "../../images/spatial.png";
import { Link } from "gatsby";
import { saveContact } from "../../data/saveEmail";

const Home = () => {
  const [whiteListEmail, setWhiteListEmail] = useState('');
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [topic, setTopic] = useState("");
  const [message, setMessage] = useState("");
  const [width, setWidth] = useState();
  const [anchor, setAnchor] = useState("");

  useEffect(() => {
    setWidth(window.innerWidth);
  });
  const [expanded, setExpanded] = React.useState("");
  const [newCards, setNewCards] = useState([]);

  async function sendEmail(sheet) {
    const whiteList = {
      email: email ? email : whiteListEmail,
      name: name,
      topic: topic,
      message: message,
    };
    const result = await saveContact(whiteList, sheet);
    if (result) {
      setWhiteListEmail('')
      setName('')
      setEmail('')
      setTopic('')
      setMessage('')
    }
  }

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "#e8fbff",
    // flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(-18 0deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const defaultUrl =
    "https://brighterheads-images.s3.us-east-2.amazonaws.com/personality/";

  useEffect(() => {
    const array = [];
    for (let i = 0; i < 9; i++) {
      let arr = Math.floor(card.length * Math.random() + 1);
      const data = card[arr];
      array.push(data);
    }
    setNewCards(array);
  }, []);

  const scroll = (value1, value2) => {
    const wearable = document.querySelector(".wearables");
    wearable.scrollBy(value1, value2);
    console.log(wearable);
  };

  useEffect(() => {
    const param = window.location.href;
    const path = param.split("#")[1];
    setAnchor(path);

    if (path) {
      const targetElement = document.getElementById(path);

      setTimeout(() => {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }, 600);
    }
  }, [anchor]);

  return (
    <>
      <Header />
      <Container>
        <title>Home</title>
        <div className="third-block">
          <div className="background-miniaturas"></div>
          <section className="block-margin">
            <h1 className="beyond-title">
              Beyond the Collectibles...
            </h1>
            <div className="beyond">
              <p style={{ marginBottom: 20 }} >
              BrighterHeads is a contemporary initiative dedicated to 
              celebrating influential individuals and their intellectual legacies, 
              while inspiring younger generations. 
              Through artistic expression and other engaging methods, 
              we aim to bring important values closer to a broader youth audience.
              </p>
            </div>
            <h1 className="bh-family-title">BrighterHeads family</h1>
            <div className="cards">
              {newCards.map((item) => (
                <img src={defaultUrl + item?.imageFile} />
              ))}
              <Link className="link" to="/gallery">
                <p>
                  See all BrighterHeads
                  <br /> in the Gallery...
                </p>
              </Link>
            </div>
          </section>
        </div>
        <div className="second-block">
          <section className="block-margin">
            <div
              style={expanded !== "" ? { marginTop: 50 } : {}}
              id="theart"
              className="text"
            >
              <h4>The art...</h4>
              <div className="text-1">
                <img src={width <= 500 ? img2 : img} />
                <div>
                  <p>
                    We have used a unique style of digital pointillism technique to reproduce the faces 
                    of some of history's brightest personalities. In our first series of 1500 NFTs, 
                    some portraits feature over 8.000 digitally handcrafted coloured objects.
                  </p>
                  <p>
                    The drawings, pictures, or texts used in the BrighterHeads NFTs and T-Shirts  
                    incorporate elements that represent, in some way, the thoughts, work, contributions, 
                    or creations of the remarkable BrighterHeads.
                  </p>
                  <p>
                    This work forms the basis for a unique contemporary collection of historical characters, 
                    brought to life with vibrant colours, and content. The art series aims to inspire and 
                    motivate young generations to engage in learning, creation, and self-development.
                  </p>
                  <p>
                    In a world filled with many BrighterHeads and more to come, 
                    this art project envisions unlimited collectibles with unique and limited series.
                  </p>
                </div>
              </div>
              <h4 className="wearables-title">T-shirts</h4>
              <div id="wearables" className="wearables">
                {wearable.map((img, index) => (
                  <a key={index} href="https://reserva.ink/brighterheads" target="_blank" rel="noopener noreferrer">
                    <img src={img} alt="shirts linked" />
                  </a>
                ))}
              </div>
              <div className="btn-scroll">
                <img onClick={() => scroll(-460, 0)} src={scrollLeft} alt="" />
                <img onClick={() => scroll(460, 0)} src={scrollRight} alt="" />
              </div>
              <div className="extra" id="roadmap" >
                <h2>
                  Mind Map
                </h2>
                <p>
                  <strong>Let’s start by highlighting a few things. </strong>
                </p>
                <p style={{ padding: 0 }} className="orange">
                  The original NFT will be delivered as a high resolution file, 
                  perfect for printing and framing on a 50x70 cm canvas.
                </p>
                <p style={{ padding: 0 }} className="torquesa">
                  In the first series, there was no use of generative design. 
                  It was mainly developed through a meticulously crafted digital process that 
                  included the use of a unique pointillism technique.
                </p>
                <p style={{ padding: 0, backgroundColor: '#ff59ee73' }} className="">
                  We are reserving 10% of each launched series, and another part of the NFTs 
                  to the buyers of the physical and original artwork from the collection.
                </p>
                <p style={{ padding: 0 }} className="green">
                  Our mission is inclusivity, and we aim to make BrighterHeads NFTs 
                  accessible and widespread. We will randomly distribute 500 NFTs to people 
                  registered on our whitelist.
                </p>
                <p style={{ padding: 0, marginBottom: 20 }} className="pink">
                  We plan to expand the creative development of our concept, T-shirts, 
                  and art to inspire  children and youth, guiding them towards wisdom, virtues, 
                  and self-development. If you want to join our collaborative process, please don't 
                  hesitate to contact us. <br></br>
                </p>
                <p style={{ padding: 0 }} className="yellow">
                  The selection and order of personalities in each of our series, 
                  as well as their placement in our galleries and on our social media channels 
                  was done randomly.
                </p>

              </div>
              <div id="whitelist" className="div-whiteList">
                <div className="whiteList">
                  <h4 id="team">Get on the whitelist</h4>
                  <p>Don’t miss the chance to win a free BrighterHeads by registering on our whitelist!</p>
                  <div className="input">
                    <input
                      type="email"
                      placeholder="e-mail address"
                      required
                      value={whiteListEmail}
                      onChange={(event) => setWhiteListEmail(event.target.value)}
                    />
                    <BsArrowRight
                      style={{ marginRight: 10, cursor: "pointer" }}
                      size={25}
                      color="#000"
                      onClick={() => sendEmail('whitelist')}
                    />
                  </div>
                </div>
                <div className="social">
                  <h4 id="team">Social</h4>
                  <div className="sociais">
                    <Link to="#">
                      <img
                        style={{ width: 50, cursor: "pointer", marginRight: 15 }}
                        src={spatial}
                      />
                    </Link>
                    <a href="https://twitter.com/brighterheads?t=yETo1yPVMYtGSwjiBEdTlQ&s=09" target="_black">
                      <FaXTwitter
                        style={{ cursor: "pointer" }}
                        size={50}
                        color="#CCFF66"
                      />
                    </a>
                    <Link to="#">
                      <img
                        style={{ width: 90, cursor: "pointer" }}
                        src={openSea}
                      />
                    </Link>
                    <a href="https://www.instagram.com/brighterheads/" target="_black">
                      <BsInstagram
                        style={{ cursor: "pointer" }}
                        size={50}
                        color="#FFB800"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <h4 id="contact">Contact</h4>
              <div className="div-contact">
                <div className="contact">
                  <input
                    type="name"
                    placeholder="Name"
                    required
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                  <input
                    type="email"
                    placeholder="e-mail address"
                    required
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />

                  <select
                    type="topic"
                    required
                    value={topic}
                    onChange={(event) => setTopic(event.target.value)}
                  >
                    <option selected value="">
                      Topic
                    </option>
                    <option value="Suggestions">Suggestions</option>
                    <option value="Troubleshoot">Troubleshoot</option>
                    <option value="About the whitelist">About the whitelist</option>
                    <option value="About the collection and arts">About the collection and arts</option>
                    <option value="About NFT's and Polygon blockchain">About NFT's and Polygon blockchain</option>
                    <option value="About our creative collaboration design program">About our creative collaboration design program</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
                <div className="textArea">
                  <textarea
                    type="text"
                    placeholder="Message"
                    required
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                  />
                  <BsArrowRight
                    style={{
                      marginRight: 10,
                      cursor: "pointer",
                      alignSelf: "flex-end",
                    }}
                    size={25}
                    color="#000"
                    onClick={() => sendEmail('contact')}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </Container >
    </>
  );
};

export default Home;
