import image0 from "../images/wearables/arte_card.jpg";
import image1 from "../images/wearables/BHMP2.png";
import image2 from "../images/wearables/BHMP3.png";
import image3 from "../images/wearables/BHANS2.png";
import image4 from "../images/wearables/BHANS3.png";
import image5 from "../images/wearables/BHCD1.png";
import image6 from "../images/wearables/BHCD2.png";
import image7 from "../images/wearables/BHCC3.png";
import image8 from "../images/wearables/BHCC2.png";
import image9 from "../images/wearables/BHIN4.png";
import image10 from "../images/wearables/BHIN3.png";
import image11 from "../images/wearables/BHJSB3.png";
import image12 from "../images/wearables/BHJSB2.png";
import image13 from "../images/wearables/BHJW2.png";
import image14 from "../images/wearables/BHJW3.png";
import image15 from "../images/wearables/BHLDV2.png";
import image16 from "../images/wearables/BHLDV1.png";
import image17 from "../images/wearables/BHLVB1.png";
import image18 from "../images/wearables/BHLVB2.png";
import image19 from "../images/wearables/BHMC1.png";
import image20 from "../images/wearables/BHMC3.png";
import image21 from "../images/wearables/BHHT3.png";
import image22 from "../images/wearables/BHHT4.png";
import image23 from "../images/wearables/BHNT3.png";
import image24 from "../images/wearables/BHNT2.png";
import image25 from "../images/wearables/BHRD3.png";
import image26 from "../images/wearables/BHRD2.png";
import image27 from "../images/wearables/BHSF2.png";
import image28 from "../images/wearables/BHSF1.png";
import image29 from "../images/wearables/BHWS2.png";
import image30 from "../images/wearables/BHWS3.png";
import image31 from "../images/wearables/arte_card.jpg"

export const wearable = [
  image0,
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
  image20,
  image21,
  image22,
  image23,
  image24,
  image25,
  image26,
  image27,
  image28,
  image29,
  image30,
  image31,
];